import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { withRouter } from 'react-router'
import _ from 'lodash'
import moment from 'moment'
import DateRangeSelector from '../DateRangeSelector'

import './ReportPageHeader.scss'

function getDatePreset(searchBy, value) {
  const datePresets = [
    { tag: 'today', startDate: moment().startOf('day').toDate(), label: 'Current Day' },
    { tag: 'yesterday', startDate: moment().subtract(1, 'day').startOf('day').toDate(), label: 'Prior Day' },
    { tag: 'week', startDate: moment().subtract(1, 'week').startOf('day').toDate(), label: 'Last 7 Days' },
    { tag: 'm2d', startDate: moment().startOf('month').toDate(), label: 'Month - To - Date' },
    { tag: 'last3', startDate: moment().subtract(3, 'months').startOf('month').toDate(), label: 'Last 3 Months' },
    { tag: 'last6', startDate: moment().subtract(6, 'months').startOf('month').toDate(), label: 'Last 6 Months' },
    { tag: 'y2d', startDate: moment().startOf('year').toDate(), label: 'Year - To - Date' },
    { tag: 'custom', startDate: null, label: 'Custom Date Range' },
  ]
  if (searchBy === 'date') {
    return datePresets.find((k) => moment(value)
      .isSame(k.startDate, 'day'))
  }
  if (searchBy === 'tag') {
    return datePresets.find((d) => d.tag === value)
  }
  return datePresets[0]
}

class ReportPageHeader extends React.Component {
  constructor(props) {
    super(props)

    const { fromDateFilter, toDateFilter } = this.props
    // set initial dates. Default to month-to-date.
    const fromDate = fromDateFilter ? moment(fromDateFilter).startOf('day') : moment().startOf('month').toDate()
    const toDate = toDateFilter ? moment(toDateFilter).endOf('day') : moment().endOf('day').toDate()
    // determine if the dates set match a preset and if so, set the label. If not, it's a custom date range.
    const datePreset = getDatePreset('date', fromDate)

    this.state = {
      startDate: fromDate,
      pendingStartDate: fromDate,
      endDate: toDate,
      pendingEndDate: toDate,
      dateLabel: (datePreset && datePreset.label) || 'Custom Date Range',
      showAccountDropdown: false,
      showDatePresetDropdown: false,
      selectedAccountId: this.props.routeParams.accountId || window.localStorage.getItem('spay-mid'),
    }
  }
  componentDidMount() {
    const { merchantList } = this.props

    // if we don't have the merchant account list, get it.
    if (_.isEmpty(merchantList)) {
      this.props.fetchAccountRequest()
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {

  }

  _toggleAccountDropdown = () => {
    this.setState({ showAccountDropdown: !this.state.showAccountDropdown, })
  }

  _toggleDatePresetDropdown = () => {
    this.setState({ showDatePresetDropdown: !this.state.showDatePresetDropdown, })
  }

  _chooseDateValues = (preset) => {
    const pendingEndDate = moment().endOf('day').toDate()
    const datePreset = getDatePreset('tag', preset || 'm2d')
    const pendingStartDate = datePreset.startDate || this.state.pendingStartDate

    return this.setState({ pendingStartDate, pendingEndDate, dateLabel: datePreset.label })
  }

  _matchMerchantName = () => {
    const mappedMerchantList = _.map(_.orderBy(this.props.merchantList,
      [(merchant) => merchant.businessCommonName.toLowerCase(), 'id'],
      ['asc', 'asc', ]
    ), (merchant) => ({
      value: merchant.id.toString(), label: merchant.businessCommonName,
    }))
    const result = mappedMerchantList.filter((cv) => cv.value === this.props.routeParams.accountId)

    return result.length > 0 ? result[0].label : ''
  }

  _mapMerchantAccountsToLinks = () =>
    _.map(_.orderBy(this.props.merchantList, [
      (merchant) => merchant.businessCommonName.toLowerCase(), 'id'], ['asc', 'asc', ]), (merchant) => (
      <span key={merchant.id} onClick={() => this._onAccountChange(merchant.id)}>
        {merchant.businessCommonName} / {merchant.id}</span>
    ))

  _applyDateFilters = () => {
    const dates = this.dateRangeSelector.getDates()
    if (!dates.fromDate || !dates.toDate) {
      this.props.showError('', 'You must enter a valid date.')
      return false
    }
    this.props.updateDateFilter(dates.fromDate, dates.toDate)

    return this.setState({
      pendingStartDate: dates.fromDate,
      pendingEndDate: dates.toDate,
      startDate: dates.fromDate,
      endDate: dates.toDate,
    }, () => {
      this.props.onDateChange(this.state.selectedAccountId)
    })
  }

  _onDateFilterChange = () => {
    if (this.state.dateLabel !== 'Custom Date Range') {
      this._applyDateFilters()
    }
  }

  _onAccountChange = (mid) => {
    if (mid) {
      window.localStorage.setItem('spay-mid', mid)
      const rootPath = this.props.location.pathname.split('/')
      this.props.router.push(`/${rootPath[1]}/${mid}`)

      this.props.setMerchantAccountId(mid)
      this.setState({
        selectedAccountId: mid,
      }, () => {
        this.props.onAccountChange(mid)
      })
    }
  }

  render() {
    const { pageTitle, merchantList, isFetching } = this.props
    const { showAccountDropdown, selectedAccountId, showDatePresetDropdown, pendingStartDate,
      pendingEndDate, dateLabel } = this.state

    const selectedAccountName = this._matchMerchantName()

    return (
      <div id='reportPageHeader'>
        <div className="header">
          <h1>{pageTitle}</h1>
          {merchantList && merchantList.length > 0 &&
            <div
              className={classnames('account-selector', { open: showAccountDropdown === true })}
              onClick={() => this._toggleAccountDropdown()}
            >
              <div className='selected-account'>
                <span className='name'>
                  <span className='dba'>{selectedAccountName}</span>
                  <span className='mid'>&nbsp;/ {selectedAccountId}</span>
                </span>
                <span className='material-icons'>expand_more</span>
              </div>
              <div className='option-list'>
                {this._mapMerchantAccountsToLinks()}
              </div>
            </div>
          }

          <div className='date-filters'>
            {isFetching && <span className='loading'>Loading...</span>}
            {!isFetching &&
              <React.Fragment>
                <div
                  className={classnames('quick-dates-selector', { open: showDatePresetDropdown === true })}
                  onClick={() => this._toggleDatePresetDropdown()}>
                  <div className='selected-option'>
                    <div className='label'>{ dateLabel }</div>
                    <span className='material-icons'>expand_more</span>
                  </div>
                  <div className='option-list'>
                    <span onClick={() => this._chooseDateValues('today')}>Current Day</span>
                    <span onClick={() => this._chooseDateValues('yesterday')}>Prior Day</span>
                    <span onClick={() => this._chooseDateValues('week')}>Last 7 Days</span>
                    <span onClick={() => this._chooseDateValues('m2d')}>Month - To - Date</span>
                    <span onClick={() => this._chooseDateValues('last3')}>Last 3 Months</span>
                    <span onClick={() => this._chooseDateValues('last6')}>Last 6 Months</span>
                    <span onClick={() => this._chooseDateValues('y2d')}>Year - To - Date</span>
                    <span onClick={() => this._chooseDateValues('custom')}>Custom Date Range</span>
                  </div>
                </div>
                <div
                  className={classnames('date-calendar', { disabled: dateLabel !== 'Custom Date Range' })}>
                  <DateRangeSelector
                    ref={ (element) => this.dateRangeSelector = element }
                    fromDate={moment(pendingStartDate)}
                    toDate={moment(pendingEndDate)}
                    onDateChange={this._onDateFilterChange}
                    useMaterialIcon={true}
                  />
                </div>
                {dateLabel === 'Custom Date Range' &&
                  <button onClick={this._applyDateFilters}>
                    <span>Apply</span><span className='material-icons'>navigate_next</span>
                  </button>}
              </React.Fragment>
            }
          </div>
        </div>
      </div>
    )
  }
}

ReportPageHeader.propTypes = {
  fetchAccountRequest: PropTypes.func.isRequired,
  getSelectedMerchantAccountId: PropTypes.func.isRequired,
  updateDateFilter: PropTypes.func.isRequired,
  setMerchantAccountId: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  onDateChange: PropTypes.func.isRequired,
  onAccountChange: PropTypes.func.isRequired,
  pageTitle: PropTypes.string,
  isFetching: PropTypes.bool,
}

ReportPageHeader.defaultProps = {

}

export default withRouter(ReportPageHeader)
